import {useState, useEffect} from 'react';
import ProjectList from './components/ProjectList';
import CurrentLocation from "./components/CurrentLocation";
import {default as checkStorage} from './utils/storage';
import db from './db/db'

import './App.css';
import RouteList from "./components/RouteList";

let projectList, setProjectList;
let currentProject, setCurrentProject;

async function setActiveProject(project) {
  try {
    project.routes = await project.getTheRoutes();
    setCurrentProject(project);
    console.log('Active project =');
    console.log(project);
  } catch (e ) {
    console.error(e)
  }

}

function initDataSource() {
  db.table('projects').toArray().then((table) => {
    setProjectList(table);
  });
}

function App() {
  [projectList, setProjectList] = useState( null );
  [ currentProject, setCurrentProject ] = useState(null);

  useEffect(() => {
    console.log('Data updated');
  });

  return (
      <div className="App">
        <header className="App-header">
          <ProjectList table={projectList} setActiveProject={setActiveProject} activeProject={currentProject}/>
          <RouteList table={ currentProject } />
          <CurrentLocation />
        </header>
      </div>
  );
}

// Initial Setup - Run Once
checkStorage();  // Check is storage is persistent.
initDataSource(); // Check Database and set initial values.
export default App;
