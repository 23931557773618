import Dexie from 'dexie';
import db from '../db/db'

class Route {
    log() {
        console.log(JSON.stringify(this));
    }

    getPOIs() {

    }

    assetList() {
        return [];
    }
}

 export default Route;