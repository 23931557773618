import Dexie from 'dexie';
import { Project, Route, Poi } from "../model/_models_"

const db = new Dexie('np_pwa_test_4');

db.version(1).stores({
    projects :  '++id, title',
    routes :    "++id,title,fkProjectId",
    pois:       "++id,title,lng,lat, fkRouteId"
});

db.projects.mapToClass(Project);
db.routes.mapToClass(Route);
db.pois.mapToClass(Poi);

db.table('projects').toArray().then((table) => {
    if (table.length > 0) {
        console.log('Projects Table already populated');
    } else {
        console.log('Projects Table empty, running dummy migrations');
        createDummyProjects();
    }
});

function createDummyProjects() {
    for(let i =0 ; i<=Math.floor(Math.random()*5)+1; i++) {
        let newProject = {
            title: "Project Group #" + i
        };
        console.log("Insert Project : ");
        console.info(newProject);
        db.projects.add(newProject).then( (id) => {
            newProject.id = id;
            createDummyRoutes(newProject)
        });

    }
}

function createDummyRoutes(project) {
    console.log("Creating Routes for Project ("+ project.title +")");
    for(let i = 0 ; i<=Math.floor(Math.random()*8)+1; i++) {
        let newRoute = {
            title: "Route #" + project.id + "/" + i,
            fkProjectId: project.id
        };
        console.log("Insert Route : " );
        console.info(newRoute);
        db.routes.add(newRoute).then( (id) => {
            newRoute.id = id;
            createDummyPOIs(newRoute);
        });

    }
}

function createDummyPOIs(route) {
    console.log("Creating POIs for Route ("+ route.title +")");

    for(let i = 0 ; i<=Math.floor(Math.random()*15)+1; i++) {
        let newPOI = {
            title: "POI #" + route.id + "/" + i,
            lat : 0,
            lng : 0,
            fkRouteId: route.id
        };
        console.log("Insert POI : " );
        console.info(newPOI)
        db.pois.add(newPOI);
    }
}

export default db;
