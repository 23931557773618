import React from 'react'

const  ProjectList = ({table, activeProject, setActiveProject})=> {
    if(table === null) return;
    return (
        <>
            <h2>Table View</h2>
            <ul>
                {table.map( (row) =>{
                    return <li className={ (row.id === activeProject?.id) ? 'Active':'' } key={row.id} row-id={row.id} onClick={ () => { setActiveProject(row) } }>
                        {row.title}
                    </li>
                })}
            </ul>
        </>
    );
}

export default ProjectList;
