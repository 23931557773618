import React from 'react'

const RouteList = ({table}) => {
    // console.log('Route list');
    // console.info("[[");
    // console.info(table);
    // console.info("]]");

    if(!table) return;

    if(!(Array.isArray(table?.routes)))  {
        console.log("Exit at array check");
        return;
    }

    let routes = table.routes;

    console.log('Route list check OK');

    return (
        <>
            <h2>Routes in this project:</h2>
            <ul>
                {routes.map((row) => {
                    return <li key={row.id} row-id={row.id}>{row.title}</li>
                })}
            </ul>
        </>
    );
}

export default RouteList;
