import db from '../db/db'


class Project {
    log() {
        console.log(JSON.stringify(this));
    }

    async getTheRoutes() {
        const routes = await  db.table('routes').where('fkProjectId').equals(this.id).toArray();

        return routes;
    }

    assetList() {
        return [];
    }
}


 export default Project;